import {Controller} from 'stimulus';

export default class extends Controller {

  static targets = ['field', 'output'];
  static classes = [ 'overLimit' ];
  static values = { characterCount: Number };

  connect() {
    this.change();
  }
  change() {
    let length = this.fieldTarget.value.length
    let max = this.characterCountValue;
    this.outputTarget.textContent = `${length} / ${max} tekens`
    if (length > max) {
      this.outputTarget.classList.add(this.overLimitClass);
    } else {
      this.outputTarget.classList.remove(this.overLimitClass);
    }
  }
}
