import {Controller} from '@hotwired/stimulus';

// Connects to data-controller="copylink"
export default class extends Controller {
  connect() {
    console.log('copylink connected');
  }
  copy(event) {
    event.preventDefault();
    // WARNING. navigator.clipboard is available in https environments only. This Will Not Work In Development.
    // (tenzij je die overbakt naar https natuurlijk)
    navigator.clipboard.writeText(event.target.href);
    event.target.text = 'Link gekopiëerd';
  }
}
